import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"

const Administracion = () => {
  const data = useStaticQuery(graphql`
    query Images_administracion {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Soluciones de Administración de Servicios Cloud | Qualoom</title>
        <meta name="title" content="Soluciones de Administración de Servicios Cloud | Qualoom"/>
        <meta name="description" content="Servicio completo en Administración de Sistemas Informáticos en Red. Qualoom te Asesoramos de manera gratuita. ¡Consúltanos e Infórmate YA!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/administracion-gobierno/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/administracion-gobierno/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/administracion-gobierno/"/>
        <meta property="og:title" content="Soluciones de Administración de Servicios Cloud | Qualoom"/>
        <meta property="og:description" content="Servicio completo en Administración de Sistemas Informáticos en Red. Qualoom te Asesoramos de manera gratuita. ¡Consúltanos e Infórmate YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/administracion-gobierno/"/>
        <meta property="twitter:title" content="Soluciones de Administración de Servicios Cloud | Qualoom"/>
        <meta property="twitter:description" content="Servicio completo en Administración de Sistemas Informáticos en Red. Qualoom te Asesoramos de manera gratuita. ¡Consúltanos e Infórmate YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/administracion-gobierno/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}},
              {"@type":"ListItem", "position":"3", "name":"Administración de sistemas informáticos en red", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/administracion-gobierno/", "name": "Administración de sistemas informáticos en red"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Administración de servicios Cloud</h1>
              <p>Cuando la arquitectura de los sistemas de información de una empresa comienza a ser compleja, existen heterogeneidad de servicios y aplicaciones en funcionamiento, las integraciones con fuentes externas son frecuentes es probable que tu equipo de sistemas de información no cumpla con los requisitos  formativos y de experiencia en su administración y gobierno. Qualoom Expertise puede ser tu aliado perfecto.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Para qué sirve la administración y gobierno?</h2><hr></hr>
          <p>Consiste en la <b>aplicación de buenas prácticas y estándares que garantizan que los costes de una infraestructura son los adecuados en función de las necesidades de la empresa</b>, que la estabilidad de los servicios es constante y que se contemplan todos o la mayoría de las situaciones de riesgo que pueden implicar una degradación o pérdida de servicio.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿Qué ofrece Qualoom?</h2><hr></hr>
          <p>Qualoom te puede ofrecer el conocimiento de un <b>equipo altamente cualificado y con amplia experiencia</b> para que tanto el funcionamiento como el mantenimiento de la infraestructura de la empresa estén siempre garantizados: optimización de costes, aplicación de parches de seguridad, realización de auditorías, integración con otros sistemas, ampliación de los servicios, asesoramiento, etc.</p><br/>
          <h3>Servicios de software personalizados</h3>
          <p>No todas las empresas y organizaciones tienen las mismas necesidades y, por tanto, no todos los servicios deben ser los mismos ni con la misma configuración. <b>Nuestro equipo realizará un análisis pormenorizado de las necesidades de tu organización,</b> así como la actual arquitectura de servicios para proponer un diseño adecuado y los cambios necesarios para alcanzar un nivel óptimo de rendimiento.</p>
          <br/>
          <h3>Gestión remota de infraestructuras informáticas</h3>
          <p>Inmersos en ecosistemas Cloud y rompiendo con los esquemas tradicionales de infraestructura inhouse, la irrupción de los servicios Cloud implica una administración y gobierno de servicios completamente remota, lo que conlleva tener en cuenta este factor para disponer de unas herramientas de conectividad remota que cumplan con los estándares de seguridad.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Administracion